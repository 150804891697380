<template>
  <div class="salesRank_page">
    <div class="box_bg">
      <div class="bg_title">
        <div class="bg_title1">中蟋网交易市场</div>
        <div class="bg_title2">各大店铺销售排行</div>
      </div>
    </div>
    <div class="box_top">
      <div class="item" @click="navigateTo(`/shopDetail/shopDetail/${top1.shop.id}`)">
        <div class="i_img">
          <img
            v-if="top1.shop && (top1.shop.cover)"
            :src="common.setImgLocation(top1.shop.cover)"
            alt=""
          />
          <img v-else src="../../static/logo.png" alt="" />
        </div>
        <div class="i_title">No.1</div>
        <div class="i_num">
          销售额<span>{{ top1.total_order }}</span
          >单
        </div>
        <div class="flex justify-content-center;">
          <div v-if="top1.shop" class="box_name">  
           <span v-if="top1.shop != null">   {{ top1.shop.store }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container flex justify-content-between">
      <div class="item" v-for="(item, index) in list" :key="index" @click="navigateTo(`/shopDetail/shopDetail/${item.shop.id}`)">
        <div class="i_img">
          <img
            v-if="item.shop.cover"
            :src="common.setImgLocation(item.shop.cover)"
            alt=""
          />
          <img v-else src="../../static/logo.png" alt="" />
        </div>
        <div class="i_title">No.{{ index + 2 }}</div>
        <div class="i_num">
          销售额<span>{{ item.total_order }}</span
          >单
        </div>
        <div class="flex justify-content-center;">
          <div class="box_name">{{ item.shop.store }}</div>
        </div>
      </div>
    </div>
    <div class="guize" @click="navigateTo(`/articleDetail/articleDetail/${18}`)">销量排行奖励规则</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      top1: {},
      list: [],
    };
  },
  methods: {
    getData() {
      this.$http.SalesRank().then((res) => {
        this.top1 = res.data.ten_xiaoshou[0];
        this.list = res.data.ten_xiaoshou.splice(1);
        console.log(this.list, "---list");
      });
    },
  },
  mounted() {
    this.getData();
  },
  created () {
    document.title = '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
 },
};
</script>
<style lang="scss" scoped>
.salesRank_page {
}
.box_bg {
  width: 100%;
  height: 370px;
  background-image: url("../../static/bg2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  .bg_title {
    position: absolute;
    width: 80%;
    background-color: rgb(247, 247, 247);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    padding: 20px;
    .bg_title1 {
      font-size: 16px;
      font-weight: 800;
    }
    .bg_title2 {
      line-height: 30px;
    }
  }
}
.box_top {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}
.container {
  padding: 50px;
  flex-wrap: wrap;
  .item {
    margin: 20px;
    width: 270px;
    height: 321px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgb(252, 252, 252);
    box-shadow: 0px 0px 10px #c5c5c5;
    cursor: pointer;
    .i_img {
      margin: 0px auto;
      margin-top: 50px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: pink;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
    .i_title {
      padding: 20px 0;
      font-size: 26px;
      text-align: center;
      font-weight: 800;
    }
    .i_num {
      text-align: center;
      span {
        color: brown;
        font-weight: 800;
        font-size: 18px;
      }
    }
    .box_name {
      padding: 10px 5px;
      display: inline-block;
      background-color: red;
      color: #fff;
      border-radius: 5px;
      justify-content: center;
      margin: 40px auto;
    }
  }
}
.guize {
  text-align: center;
  font-size: 28px;
  cursor: pointer;
}
</style>